<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

::-webkit-scrollbar {
  width: 0;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .1);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

html {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .1) transparent;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .1);
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .1) transparent;
}
</style>
