import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/ResolveAddress',
      name: 'ResolveAddress',
      meta: {
        requireAuth: true,  // 判断是否需要登录
      },
      component: resolve => require(['@/components/ResolveAddress.vue'],resolve)
    },{
      path: '/',
      name: 'AppMenu',
      meta: {
        requireAuth: true,  // 判断是否需要登录
      },
      component: resolve => require(['@/components/AppMenu.vue'],resolve)
    },{
      path: '/login',
      name: 'login',
      meta: {
        requireAuth: false,  // 判断是否需要登录
      },
      component: resolve => require(['@/components/LoginUser.vue'],resolve)
    }
  ],

})
router.beforeEach((to,from,next) => {
  if(to.meta.requireAuth){
    if(window.localStorage.getItem("authToken")){
      next()
    }else{
      next("/login")
    }
  }else{
    next();
  }
})

export default router;
